import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import RenderBlocks, { Blocks } from 'utils/RenderModules';

type StartpageProps = {
  data: GatsbyTypes.StartpageQuery;
};

const IndexPage: React.FC<StartpageProps> = ({ data }) => {
  if (!data.startpage) return null;

  return (
    <>
      <HelmetDatoCms seo={data.startpage?.seoMetaTags} />
      <RenderBlocks data={data.startpage.modules as Blocks[]} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query Startpage {
    startpage: datoCmsStartpage {
      seoMetaTags {
        tags
      }
      modules {
        ...Accordion
        ...AccordionEnd
        ...BigHero
        ...CardBlock
        ...CaseBlock
        ...CaseLink
        ...Diagram
        ...Form
        ...Hero
        ...IconTextList
        ...MediaComponent
        ...Spacing
        ...Testimonial
        ...TitleAndImage
        ...TwoTextColumn
        ...TwoTextImageColumn
        ...UtilisedNavigation
      }
    }
  }
`;
